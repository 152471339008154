import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import {replaceStrPatterns} from '../../utilities/general';

export default function Footer(props) {
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    const root = useContext(RootContext);

    return (
        <footer className={"footer"+(root.lightMode?" light-mode":"")}>
            <div className="inner">
                <span className="copyright">{replaceStrPatterns(config.copyright, [{pattern:"{{year}}", value:currYear}])}</span>
                <a className="privacy" href={config.url} target="_blank">{config.privacy}</a>
            </div>
        </footer>
    )
}
