import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";

export default function PeopleQuotes(props) {
    const root = useContext(RootContext);

    return (
        <div className={"people-quotes"+(root.lightMode?" light-mode":"")}>
            {props.list.map((quote, key) => {
                return(
                    <div className="quote" key={key}>
                        <div className="box">
                            <div className="paragraphs">
                                {quote.body.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                            </div>

                            <div className="from">
                                <span className="initials">{quote.initials}</span>
                                <span className="title">{quote.title}</span>
                            </div>

                            <span className="mark open"></span>
                            <span className="mark close"></span>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
