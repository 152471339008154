import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";

export default function IconBoxes(props) {
    const root = useContext(RootContext);

    return (
        <div className={"icon-boxes"+(root.lightMode?" light-mode":"")}>
            <div className="boxes">
                {props.boxes.map((benefit, key) => {
                    return(
                        <div className="box" key={key}>
                            <div className="icon" style={{backgroundImage: "url("+media[benefit.icon]+")"}}></div>

                            <div className="text-holder">
                                <p className="text">{benefit.txt}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
