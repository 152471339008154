import React, {useEffect, useState, useMemo, createContext} from 'react';
import './styles/general.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {scrollToElementId} from './utilities/general';

//Pages
import Home from "./pages/home/main";
import CaseStudies from "./pages/case-studies/main";
import NotFound from "./pages/not-found/main";

export const RootContext = createContext(null);

export default function Web() {
    const [lightMode, setLightMode] = useState(false);

    const contextValue = useMemo(() => {
        return {lightMode, setLightMode};
    }, [lightMode]);

    //On component mount
    useEffect(() => {
        var hash = window.location.hash;

        if (hash!=="") {
            scrollToElementId(hash.slice(1)); //Remove first character to get id
        }
    }, []);

    return (
        <RootContext.Provider value={contextValue}>
            <BrowserRouter basename="/">
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="case-studies" element={<CaseStudies />}/>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </RootContext.Provider>
    )
}
