const media = {
    //Product logos

    coguide_white: require("./imgs/coguide_white.svg").default,
    coguide_black: require("./imgs/coguide_black.svg").default,

    codriver_white: require("./imgs/codriver_white.svg").default,
    codriver_black: require("./imgs/codriver_black.svg").default,

    coreview_white: require("./imgs/coreview_white.svg").default,
    coreview_black: require("./imgs/coreview_black.svg").default,

    cobooker_white: require("./imgs/cobooker_white.svg").default,
    cobooker_black: require("./imgs/cobooker_black.svg").default,

    coscore_white: require("./imgs/coscore_white.svg").default,
    coscore_black: require("./imgs/coscore_black.svg").default,


    //Brand logos

    toyota_white: require("./imgs/toyota_white.svg").default,
    toyota_black: require("./imgs/toyota_black.svg").default,

    lexus_white: require("./imgs/lexus_white.svg").default,
    lexus_black: require("./imgs/lexus_black.svg").default,

    macallan_white: require("./imgs/macallan_white.svg").default,
    macallan_black: require("./imgs/macallan_black.svg").default,

    hyundai_white: require("./imgs/hyundai_white.svg").default,
    hyundai_black: require("./imgs/hyundai_black.svg").default,


    //Social logos

    facebook_logo: require("./imgs/facebook_logo.svg").default,
    instagram_logo: require("./imgs/instagram_logo.svg").default,
    linkedin_logo: require("./imgs/linkedin_logo.svg").default,
    x_logo: require("./imgs/x_logo.svg").default,


    //Other images

    illustration: require("./imgs/illustration.svg").default,

    video_thumb1: require("./imgs/video_thumb1.png"),
    video_thumb2: require("./imgs/video_thumb2.png"),
    video_thumb3: require("./imgs/video_thumb3.png"),
    video_thumb4: require("./imgs/video_thumb4.png"),

    benefit_icon1: require("./imgs/benefit_icon1.svg").default,
    benefit_icon2: require("./imgs/benefit_icon2.svg").default,
    benefit_icon3: require("./imgs/benefit_icon3.svg").default,
    benefit_icon4: require("./imgs/benefit_icon4.svg").default,

    technical_icon1: require("./imgs/technical_icon1.svg").default,
    technical_icon2: require("./imgs/technical_icon2.svg").default,
    technical_icon3: require("./imgs/technical_icon3.svg").default,
    technical_icon4: require("./imgs/technical_icon4.svg").default,

    study1_img1: require("./imgs/study1/img1.png"),
    study1_img2: require("./imgs/study1/img2.png"),
    study1_img3: require("./imgs/study1/img3.png"),
    study1_img4: require("./imgs/study1/img4.png"),

    study2_img1: require("./imgs/study2/img1.png"),
    study2_img2: require("./imgs/study2/img2.png"),
    study2_img3: require("./imgs/study2/img3.png"),
    study2_img4: require("./imgs/study2/img4.png"),

    study3_img1: require("./imgs/study3/img1.png"),
    study3_img2: require("./imgs/study3/img2.png"),
    study3_img3: require("./imgs/study3/img3.png"),
    study3_img4: require("./imgs/study3/img4.png"),


    //Videos

    video_codriver: require("./video/codriver.mp4"),
    video_coreview: require("./video/coreview.mp4"),
    video_cobooker: require("./video/cobooker.mp4"),
    video_coscore: require("./video/coscore.mp4")
}

export default media;