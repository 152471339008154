import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import {scrollToElementId} from '../../utilities/general';
import Layout from "../../components/layout/main";
import AnimationOnScroll from "../../components/animation-on-scroll/main";
import ArrowButton from "../../components/arrow-button/main";
import SectionHeading from "../../components/section-heading/main";
import AppBoxes from "../../components/app-boxes/main";
import VideoBox from "../../components/video-box/main";
import IconBoxes from "../../components/icon-boxes/main";
import BrandsBox from "../../components/brands-box/main";
import Accordion from "../../components/accordion/main";
import PeopleQuotes from "../../components/people-quotes/main";
import CaseBoxes from "../../components/case-boxes/main";
import ContactSection from "../../components/contact-section/main";


export default function Home(props) {
    const [showTop, setShowTop] = useState(false);
    const [currPlayingVideo, setCurrPlayingVideo] = useState(0);

    const root = useContext(RootContext);

    //On component mount
    useEffect(() => {
        window.onscroll = function(e) {
            //Detect if user is at 300 offset from the bottom of the page
            if ((window.innerHeight+Math.round(window.scrollY))+300>=document.body.offsetHeight) {
                setShowTop(true);
            }
            else {
                setShowTop(false);
            }
        };
    }, []);

    return (
        <div className={"home"+(root.lightMode?" light-mode":"")}>
            <Layout>
                <div className="intro-block">
                    <div className="inner-content">
                        <div className="text-holder">
                            <h2 className="heading">{config.intro.heading}</h2>
                            <h3 className="subheading">{config.intro.subheading}</h3>
                            <ArrowButton label={config.intro.button} onClick={()=>{scrollToElementId(config.intro.elementId);}} />
                        </div>

                        <div className="illustration-holder">
                            <img className="illustration" src={media[config.intro.img]} />
                        </div>
                    </div>
                </div>

                <div id="apps" className="apps-block">
                    <div className="inner-content">
                        <SectionHeading text={config.apps.heading} />

                        <AppBoxes list={config.apps.list} />
                    </div>
                </div>

                <div id="codriver" className="codriver-block">
                    <div className="inner-content">
                        <VideoBox content={config.codriver} videoId={1} currPlayingVideo={currPlayingVideo} onPlay={()=>{setCurrPlayingVideo(1)}} />
                    </div>
                </div>

                <div id="coreview" className="coreview-block">
                    <div className="inner-content">
                        <VideoBox content={config.coreview} videoId={2} currPlayingVideo={currPlayingVideo} onPlay={()=>{setCurrPlayingVideo(2)}} />
                    </div>
                </div>


                <div id="cobooker" className="cobooker-block">
                    <div className="inner-content">
                        <VideoBox content={config.cobooker} videoId={3} currPlayingVideo={currPlayingVideo} onPlay={()=>{setCurrPlayingVideo(3)}} />
                    </div>
                </div>

                <div id="coscore" className="coscore-block">
                    <div className="inner-content">
                        <VideoBox content={config.coscore} videoId={4} currPlayingVideo={currPlayingVideo} onPlay={()=>{setCurrPlayingVideo(4)}} />
                    </div>
                </div>

                <div id="benefits" className="benefits-block">
                    <div className="inner-content">
                        <SectionHeading text={config.benefits.heading} />

                        <IconBoxes boxes={config.benefits.list} />
                    </div>
                </div>

                <div className="brands-block">
                    <div className="inner-content">
                        <SectionHeading text={config.brands.heading} />

                        <BrandsBox list={config.brands.list} />
                    </div>
                </div>

                <div className="technical-block">
                    <div className="inner-content">
                        <SectionHeading text={config.technical.heading} />

                        <IconBoxes boxes={config.technical.list} />
                    </div>
                </div>

                <div className="faq-block">
                    <div className="inner-content">
                        <SectionHeading text={config.faq.heading} />

                        <Accordion bars={config.faq.questions} type="multiple">
                            {config.faq.answers.map((answer, key) => {
                                return(
                                    <div className="answer" key={key}>
                                        <p className="text" dangerouslySetInnerHTML={{__html:answer}}></p>
                                    </div>
                                );
                            })}
                        </Accordion>
                    </div>
                </div>

                <div className="people-block">
                    <div className="inner-content">
                        <SectionHeading text={config.people.heading} />

                        <PeopleQuotes list={config.people.list} />
                    </div>
                </div>

                <div className="studies-block">
                    <div className="inner-content">
                        <SectionHeading text={config.studies.heading} />

                        <CaseBoxes list={config.studies.list} />
                    </div>
                </div>

                <a id="contact"></a>

                <ContactSection />

                <div className="button-holder">
                    <a className={"top-btn"+(showTop?" show":"")} onClick={()=>{window.scroll({top:0, behavior:'smooth'})}}></a>
                </div>
            </Layout>
        </div>
    )
}
