import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import ContactForm from "../contact-form/main";

export default function SectionHeading(props) {
    const root = useContext(RootContext);

    return (
        <div className={"contact-section"+(root.lightMode?" light-mode":"")}>
            <div className="inner-content">
                <div className="cols">
                    <div className="col-left">
                        <h2 className="heading">{config.heading}</h2>
                        <span className="phone">{config.phone}</span>
                        <span className="email">{config.email}</span>

                        <div className="social">
                            <div className="links">
                                {config.links.map((link, key) => {
                                    return(
                                        <a key={key} className="link" href={link.url} target="_blank" style={{backgroundImage:"url("+media[link.logo]+")"}}></a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-right">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    )
}
