import React, {useState, useEffect, useContext, useRef} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";
import {scrollToElementId} from '../../utilities/general';

export default function VideoBox(props) {
    const [videoOverlay, setVideoOverlay] = useState(true);

    const root = useContext(RootContext);

    const refVideo = useRef(null);

    //On component mount
    useEffect(() => {
        if (props.currPlayingVideo!==props.videoId) {
            refVideo.current.pause();
            setVideoOverlay(true);
        }

    }, [props.currPlayingVideo]);

    return (
        <div className={"video-box"+(root.lightMode?" light-mode":"")}>
            <div className="video-holder">
                <div className="logo-holder">
                    <img className="logo" src={media[root.lightMode?props.content.logo.light:props.content.logo.dark]} />
                </div>

                <div className="video-wrapper">
                    <video ref={refVideo} controls controlsList="nodownload" >
                        <source src={media[props.content.video]} type="video/mp4" />
                    </video>

                    { videoOverlay &&
                        <div className="overlay">
                            <img className="thumb" src={media[props.content.thumb]} />

                            <div className="shade"></div>

                            <span className="play-btn" onClick={onPlayClick}></span>
                        </div>
                    }
                </div>
            </div>

            <div className="text-holder">
                <ul className="bullets">
                    {props.content.bullets.map((bullet, key) => {
                        return(
                            <li className="bullet" key={key} dangerouslySetInnerHTML={{__html:bullet}}></li>
                        );
                    })}
                </ul>

                <a className="next-btn" onClick={()=>{scrollToElementId(props.content.next.elementId);}}>
                    <span className="label">{props.content.next.label}</span>
                    <span className="arrow">
                        <span className="line"></span>
                    </span>
                </a>
            </div>
        </div>
    );

    function onPlayClick(e) {
        setVideoOverlay(false);

        refVideo.current.currentTime = 0;
        refVideo.current.play();

        props.onPlay();
    }
}
