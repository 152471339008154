import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";

export default function BrandsBox(props) {
    const root = useContext(RootContext);

    return (
        <div className={"brands-box"+(root.lightMode?" light-mode":"")}>
            {props.list.map((brand, key) => {
                return(
                    <div className={"brand brand"+(key+1)} key={key}>
                        <img src={media[root.lightMode?brand.light:brand.dark]} />
                    </div>
                );
            })}
        </div>
    )
}
