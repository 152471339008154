import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";
import { useNavigate } from "react-router-dom";
import ArrowButton from "../arrow-button/main";

export default function CaseBoxes(props) {
    const navigate = useNavigate();

    const root = useContext(RootContext);

    return (
        <div className={"case-boxes"+(root.lightMode?" light-mode":"")}>
            {props.list.map((study, key) => {
                return(
                    <div className={"study study"+(key+1)} key={key}>
                        <div className="box">
                            <div className="brands">
                                <div className="logos">
                                    {study.brands.map((brand, key) => {
                                        return(
                                            <img className={"img"+(key+1)} key={key} src={media[root.lightMode?brand.light:brand.dark]} />
                                        );
                                    })}
                                </div>
                            </div>

                            <ArrowButton label={study.button} onClick={()=>{window.scroll({top:0}); navigate(study.pathname);}} />
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
