import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import Layout from "../../components/layout/main";
import CaseTabs from "../../components/case-tabs/main";

export default function CaseStudies(props) {
    const [showHome, setShowHome] = useState(false);

    const root = useContext(RootContext);

    //On component mount
    useEffect(() => {
        setTimeout(()=>{
            setShowHome(true);
        }, 500);
    }, []);

    return (
        <div className={"case-studies"+(root.lightMode?" light-mode":"")}>
            <Layout>
                <div className="button-holder">
                    <a className={"home-btn"+(showHome?" show":"")} href="/"></a>
                </div>

                <div className="tabs-holder">
                    <div className="inner-content">
                        <CaseTabs content={config.tabs}>
                            {config.tabs.reveals.map((reveal, i) => {
                                return(
                                    <div className="blocks" key={i}>
                                        {reveal.blocks.map((block, j) => {
                                            return(
                                                <div className={"block "+(j%2===0?"block-left":"block-right")} key={j}>
                                                    <div className={"img-holder"+(block.hasOwnProperty("logo")?" logo-holder":"")}>
                                                        <img src={block.hasOwnProperty("logo")?media[root.lightMode?block.logo.light:block.logo.dark]:media[block.img]} />
                                                    </div>
                                                    <div className="txt-holder">
                                                        <h3 className="heading">{block.heading}</h3>

                                                        <div className="paragraphs">
                                                            {block.body.map((paragraph, key) => {
                                                                return(
                                                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </CaseTabs>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
