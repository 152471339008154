import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";
import Cookies from 'js-cookie';

export default function ModeSwitch(props) {
    const [transDuration, setTransDuration] = useState(0);

    const root = useContext(RootContext);

    //On component mount
    useEffect(() => {
        var mode = Cookies.get('mode');

        if (mode==="light") {
            root.setLightMode(true);
        }

        //Enable transition
        setTimeout(()=>{
            setTransDuration(0.3);
        }, 500);
    }, []);

    return (
        <label className={"mode-switch"+(root.lightMode?" enabled":"")}>
            <input type="checkbox" checked={root.lightMode} onChange={onSwitchChange} />
            <span className="slider" style={{transitionDuration:transDuration+"s"}}>
                <span className="circle" style={{transitionDuration:transDuration+"s"}}></span>
            </span>
        </label>
    )

    function onSwitchChange(e) {
        var temp = root.lightMode;

        root.setLightMode(!temp);
        Cookies.set('mode', temp?'dark':'light');
    }
}
