export function replaceStrPatterns(original, replacements) {
    var result = original;
    var i;

    for (i=0; i<replacements.length; i++) {
        result = result.replace(new RegExp(replacements[i].pattern, 'g'), replacements[i].value);
    }

    return result;
}

export function scrollToElementId(id) {
    var element = document.getElementById(id);

    if (element!==null) {
        element.scrollIntoView({behavior:'smooth'});
    }
}

export async function sendEmail(data, onSent) {
    var xhttp = new XMLHttpRequest();
    var formData = new FormData();

    xhttp.open("POST", "https://beta.coguide.net/mail.php", true);
    xhttp.onreadystatechange = function() {
        if (xhttp.readyState===XMLHttpRequest.DONE) {
            onSent(xhttp.responseText);
        }
    };

    formData.append("from", data.email);
    formData.append("to", "diego.bouzas@ignitiononline.com");
    formData.append("subject", "New Email from CoGuide Website Contact Page");
    formData.append("message", "Name: "+data.name+"\nCompany: "+data.company+"\nEmail: "+data.email+"\n\n"+data.message);

    xhttp.send(formData);
}
