import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";

export default function CaseTabs(props) {
    const [activeTab, setActiveTab] = useState(1);

    const root = useContext(RootContext);

    //On component mount
    useEffect(() => {
        var hash = window.location.hash;
        var tabNum = 1;

        if (hash!=="") {
            hash = hash.slice(1); //Remove first character
            tabNum = parseInt(hash); //Convert to number

            if (tabNum!==1 && tabNum!==2 && tabNum!==3) {
                tabNum = 1;
            }

            setActiveTab(tabNum);
        }
    }, []);

    return (
        <div className={"case-tabs"+(root.lightMode?" light-mode":"")}>
            <div className="controls">
                <div className="title-holder">
                    <span className="title">{props.content.title}</span>
                </div>

                <div className="tabs">
                    {props.content.reveals.map((reveal, key) => {
                        return(
                            <div className={"tab"+(activeTab===key+1?" active":"")} key={key}>
                                <div className="btn" onClick={()=>{onTabClick(key+1)}}>
                                    <span className="num">{"0"+(key+1)}</span>
                                    <span className="name">{reveal.heading}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="reveals">
                {props.content.reveals.map((reveal, key) => {
                    return(
                        <div className={"reveal"+(activeTab===key+1?" active":"")} key={key}>
                            {props.children[key]}
                        </div>
                    );
                })}
            </div>
        </div>
    )

    function onTabClick(id) {
        setActiveTab(id);
    }
}
