import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import {sendEmail} from '../../utilities/general';
import ArrowButton from "../arrow-button/main";
import Loader from "../loader/main";

export default function ContactForm(props) {
    const [formDisabled, setFormDisabled] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");

    const root = useContext(RootContext);

    return (
        <form className={"contact-form"+(root.lightMode?" light-mode":"")} onSubmit={onFormSubmit}>
            <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="name" disabled={formDisabled?"disabled":""} placeholder={config.name} value={name} onChange={(e)=>{setName(e.target.value)}} />

            <input className={"text-input"+(formDisabled?" disabled":"")} type="email" name="email" disabled={formDisabled?"disabled":""} placeholder={config.email} value={email} onChange={(e)=>{setEmail(e.target.value)}} />

            <input className={"text-input"+(formDisabled?" disabled":"")} type="text" name="company" disabled={formDisabled?"disabled":""} placeholder={config.company} value={company} onChange={(e)=>{setCompany(e.target.value)}} />

            <textarea className={"text-area"+(formDisabled?" disabled":"")} name="message" disabled={formDisabled?"disabled":""} placeholder={config.message} value={message} onChange={(e)=>{setMessage(e.target.value)}}></textarea>

            {emailSent ?
                <p className="thanks">{config.thanks}</p>
            :
                isSending ?
                    <Loader />
                :
                    <ArrowButton type="submit" label={config.button} onClick={onFormSubmit} />
            }        
        </form>
    )

    function isEmailCorrect(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function onFormSubmit(e) {
        e.preventDefault();

        //Validate form
        if (name==="" || email==="" || message==="") {
            alert(config.error1);
            return;
        }

        if (isEmailCorrect(email)===false) {
            alert(config.error2);
            return;
        }

        //Send email
        setIsSending(true);

        sendEmail(
            {
                email: email,
                name: name,
                company: company,
                message: message
            },
            onEmailSent
        );
    }

    function onEmailSent(res) {
        setFormDisabled(true);
        setIsSending(false);
        setEmailSent(true);
    }
}
