import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";
import {scrollToElementId} from '../../utilities/general';
import ArrowButton from "../arrow-button/main";

export default function AppBoxes(props) {
    const root = useContext(RootContext);

    return (
        <div className={"app-boxes"+(root.lightMode?" light-mode":"")}>
            {props.list.map((app, key) => {
                return(
                    <div className="box" key={key}>
                        <div className="box-inner">
                            <div className="img-holder">
                                <img src={media[root.lightMode?app.img.light:app.img.dark]} />
                            </div>

                            <ArrowButton label={app.button} onClick={()=>{scrollToElementId(app.elementId);}} />
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
